<template>
  <div id="restaurants" class="container container-restaurant mt-5">
    <!-- <v-container class="secondary mt-5"> -->
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="filteredRestaurants" :search="search" :footer-props="footerProps"
          hide-default-footer>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="page-title">Выберите Ресторан
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <div class="search-component">
                <input type="search" class="search-component__input mr-10" v-model="search" append-icon="mdi-magnify"
                  placeholder="Поиск" single-line hide-details />
                <svg width="17" height="17" class="search-component__icon">
                  <use href="../assets/icons/icons.svg#search-icon"></use>
                </svg>
              </div>
              <button id="staff-add-button" @click="createItem">
                Создать новый ресторан +
              </button>
              <v-dialog v-model="dialogCreate" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Добавить ресторан
                  </v-card-title>
                  <v-card-text>
                    <v-text-field v-model="newItem.name" label="Название приложения(package.name)" />
                    <v-select v-model="newItem.address" label="Выберите тип приложения" item-text="label"
                      item-value="value" />
                    <v-text-field v-model="newItem.description" label="Описание" />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="createItemConfirm">Добавить</v-btn>
                    <v-btn color="blue darken-1" text @click="closeCreate">Отменить</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Вы точно хотите удалить данное приложение?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Отменить</v-btn>
                    <v-btn color="red darken-1" text @click="deleteItemConfirm">Удалить</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td @click="entetRestaurant(item)">{{ item.restaurant.name }}</td>
              <td @click="entetRestaurant(item)">{{ item.restaurant.address }}</td>
              <td @click="entetRestaurant(item)">{{ item.restaurant.description }}</td>
              <!-- <td style="text-align: right;">
                  <v-icon medium @click="deleteItem(item)"
                    >mdi-dots-vertical</v-icon
                  >
              </td> -->
            </tr>
          </template>
          <template v-slot:no-data>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </template>
          <template v-slot:footer>
            <div class="v-data-footer">
              <div class="v-data-footer__select">
                Кол-во приложений на странице:
                <v-select class="custom-select" v-model="itemsPerPage" :items="footerProps.itemsPerPageOptions"
                  @change="changeItemsPerPage" hide-details></v-select>
              </div>
              <div class="v-data-footer__pagination">
                {{ (currentPage - 1) * itemsPerPage + 1 }} -
                {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                {{ totalItems }}
              </div>
              <div class="v-data-footer__icons-before">
                <button type="button" :disabled="currentPage === 1"
                  class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                  :class="{ 'v-btn--disabled': currentPage === 1 }" aria-label="Предыдущая страница"
                  @click="goToPreviousPage">
                  <span class="v-btn__content">
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left theme--light"></i>
                  </span>
                </button>
              </div>
              <div class="v-data-footer__icons-after">
                <button type="button" :disabled="currentPage === totalPages"
                  class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                  :class="{ 'v-btn--disabled': currentPage === totalPages }" aria-label="Следующая страница"
                  @click="goToNextPage">
                  <span class="v-btn__content">
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-right theme--light"></i>
                  </span>
                </button>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- </v-container> -->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
export default {
  name: "RestaurantChoose",
  data: () => ({
    dialogDelete: false,
    dialogCreate: false,
    search: "",
    newItem: {
      name: "",
      address: "",
      description: "",
    },
    selectedType: null,
    // applicationTypes: [
    //   { label: "CustomerApp", value: 1 },
    //   { label: "RestaurantApp", value: 2 },
    //   { label: "WebApp", value: 3 },
    // ],
    headers: [
      {
        text: "Название Ресторана",
        align: "start",
        value: "restaurant.name",
      },
      { text: "Адресс", value: "restaurant.address" },
      { text: "Описание", value: "restaurant.description" },
      // { text: "Действия",  align: "end", value: "actions", sortable: false },
    ],
    restaurants: [],
    profiles: [],
    error: null,
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 0,
    totalPages: 0,
  }),

  created() {
    this.initialize();
    // this.profileInfo();
  },
  computed: {
    filteredRestaurants() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      if (this.selectedType) {
        console.log(this.selectedType);
        return this.restaurants.items.filter(
          // (item) => item.applicationType === this.selectedType
        );
      } else {
        return this.restaurants;
      }
    },
    footerProps() {
      return {
        itemsPerPageOptions: [3, 5, 7, 10], // Опции количества элементов на странице
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        totalItems: this.totalItems,
      };
    },
  },
  methods: {
    ...mapMutations(["setToken", "setData", "setLogged", "setRoles", "setCurrentRest"]),
    initialize() {
      this.$http
        .get(`/api/Auth/WhoAmI`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // В данном случае, предполагается, что response.data содержит объект с информацией о пагинации и массивом элементов.
          this.restaurants = response.data; // Получаем массив элементов
        })
        .catch((error) => (this.error = error));
    },
    //   rootRestaurantList() {
    //     this.$http
    //     .put(`/api/Restaurant/PagedList`, {
    //       //     params: {
    //       // pageNumber: this.currentPage,
    //       // pageSize: this.itemsPerPage,
    //       //   },
    //       pageNumber: this.currentPage,
    //       pageSize: this.itemsPerPage,
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       // В данном случае, предполагается, что response.data содержит объект с информацией о пагинации и массивом элементов.
    //       this.restaurants = response.data; // Получаем массив элементов
    //       // this.currentPage = response.data.pageNumber; // Текущая страница
    //       this.totalPages = response.data.totalPages; // Общее количество страниц
    //       this.totalItems = response.data.totalCount; // Общее количество элементов
    //       console.log(this.totalItems);
    //       // this.hasNextPage = response.data.hasNextPage;
    //       // this.hasPreviousPage = response.data.hasPreviousPage;
    //       // // Возможно, также нужно обработать значения hasNextPage и hasPreviousPage в вашем компоненте.
    //     })
    //     .catch((error) => (this.error = error));
    //   },
    goToNextPage() {
      if (this.currentPage < this.totalItems) {
        this.currentPage++;
        this.initialize();
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.initialize();
      }
    },
    changeItemsPerPage() {
      this.currentPage = 1; // Сбрасываем текущую страницу при изменении количества элементов на странице
      this.initialize(); // Вызов метода для загрузки данных с новым количеством элементов на странице
    },
    //   profileInfo() {
    //     this.$http
    //       .get("/api/Contact/GetMyProfile")
    //       .then((response) => {
    //         this.profiles = response.data;
    //       })
    //       .catch((error) => (this.error = error));
    //   },

    deleteItem(item) {
      this.editedIndex = this.applications.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    entetRestaurant(item) {
      // this.editedIndex = this.restaurants.items.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.$http
        .get(`/api/Auth/GetEmployeeToken?employeeId=${item.id}`)
        .then((emp) => {
          this.$store.commit("setToken", emp.data.accessToken);
          this.$store.commit("setExpireDate", emp.data.accessTokenExpireDateUtc);
          // this.$store.commit("setLogged", true);
          this.$store.commit("setCurrentRest", item);
          // axios
          //   .get(process.env.VUE_APP_API_HOST + `/api/Contact/GetMyProfile`)
          //   .then((profile) => {
          //     commit("setData", profile.data);
          //   });
          this.$router.push({ path: `/RestaurantPage/${item.restaurantId}` });
        });
    },
    deleteItemConfirm() {
      this.$http
        .delete(`/api/Application/${this.editedItem.id}`)
        .then(() => {
          this.applications.items.splice(this.editedIndex, 1);
          this.closeDelete();
        })
        .catch((error) => {
          alert(error);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
    },

    closeCreate() {
      this.dialogCreate = false;
    },

    createItemConfirm() {
      this.$http
        .post("/api/Application", this.newItem)
        .then(() => {
          this.initialize();
          this.dialogCreate = false;
          this.newItem = {
            name: "",
            address: "",
            description: "",
          };
        })
        .catch((error) => {
          this.error = error;
        });
    },

    editItem(item) {
      this.$router.push({ path: `/admin/application/${item.id}` });
    },
  },
};
</script>
<style scoped>
.custom-select .v-list {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
}

.custom-select .v-list-item {
  width: 100%;
  text-align: center !important;
}

.v-btn--disabled {
  color: #ccc;
  /* Цвет текста */
  cursor: not-allowed;
  /* Курсор "не разрешено" */
}

::v-deep .v-toolbar__content {
  align-items: center !important;
}

/* Применяем стили для выпадающего списка */
.custom-select .v-select__slot {
  padding: 7px;
  /* Уменьшаем вертикальный отступ */
  min-height: 30px;
  /* Минимальная высота */
  text-align: center;
}

.custom-select .v-select__slot>.v-list-item__content {
  text-align: center;
}

/* .v-list-item__content {
    flex-direction: column;
  } */
/* Применяем стили для элементов списка */
.custom-select .v-list-item {
  height: 24px;
  /* Высота элемента */
  min-height: 24px;
  /* Минимальная высота элемента */
  line-height: 24px;
  width: 100%;
  /* Высота строки */
}

/* Применяем стили для текста элемента списка */
.custom-select .v-list-item__title {
  font-size: 12px;
  text-align: center;
  padding-left: 10px;
}

/* Применяем стили для выпадающего списка при наведении */
.custom-select .v-list-item:hover {
  background-color: #f0f0f0;
  /* Цвет фона при наведении */
}

.custom-select {
  position: relative;
  width: 294px;
  cursor: pointer;
  margin-left: 19px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

.select-arrow {
  position: absolute;
  top: 48%;
  right: 30px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  /* Цвет стрелочки */
}

.col-description,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  margin-bottom: 0px;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}

.col-content {
  display: flex;
  align-items: flex-start;
}

.role-margin {
  margin-right: 37px;
}

.col-content-2 {
  display: flex;
  align-items: center;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  min-height: 20px;
  /* Минимальная высота для textarea */
  width: 100%;
  margin-left: 18px;
}

::v-deep .v-dialog {
  border-radius: 20px;
  background: #fff;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none;
}

.modal-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.page-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.container-restaurant {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 54px 0px rgba(170, 170, 170, 0.25);
}

.search-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-notfound {
  /* display: flex; */
  margin: 0 auto;
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

}

.search-component {
  max-width: 606px;
  width: 100%;
  height: 45px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row;
}

.search-component__input {
  border-radius: 100px;
  border: 1px solid var(--gray-5, #E0E0E0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 10.5px;
  right: 55px;
}

#staff-add-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #FFF);
  gap: 12px;
  padding: 10px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
}

::v-deep tbody tr:nth-child(2n) {
  background: rgba(242, 242, 246, 0.5);
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .v-data-table__wrapper>table>thead>tr>th {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: thin solid #848484;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up,
::v-deep .v-data-table-header__icon.mdi-arrow-down {
  display: inline-block;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-up */
  font-family: "Material Design Icons";
}

/* Значок сортировки по убыванию */
::v-deep .v-data-table-header__icon.mdi-arrow-up.rev::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down.rev::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-down */
  font-family: "Material Design Icons";
}
</style>